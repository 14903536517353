import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/Layout"
import HeadMeta from "../components/HeadMeta"
import PageHeader from "../components/PageHeader"
import Breadcrumb from "../components/Breadcrumb"
import LanguageShowcase from "../components/LanguageShowcase"
import Phrase from "../components/Phrase"
import ExternalFile from "../components/ExternalFile"
import Button from "../components/Button"
import RequestAPhrase from "../components/RequestAPhrase"

import * as css from "./languagePhrase.module.scss";

export default ({ data }) => {
  const language = data.languagesJson.fields;
  const phrase = data.phrasesJson.fields;

  const translationEdges = data.allTranslationsJson.edges
  const translationList = translationEdges.length && (
    <React.Fragment>
      {translationEdges.map((translationEdge, index) => (
        <div key={index}>
          {translationEdge.node.fields.text &&
            translationEdge.node.fields.text.length > 0 && (
              <LanguageShowcase
                languageCode={language.isoCode}
                languageName={language.name}
                direction={language.direction}
                fontFile={
                  language.font && language.font.length > 0
                    ? `fonts/${language.name}/${language.font}.ttf`
                    : null
                }
              >
                {translationEdge.node.fields.text}
              </LanguageShowcase>
            )}

          {(!translationEdge.node.fields.text ||
            !translationEdge.node.fields.text.length) && (
            <p className={css.translationError}>Text translation not available.</p>
          )}

          {translationEdge.node.fields.graphic && (
            <ExternalFile
              filename={translationEdge.node.fields.graphic}
              languageName={language.name}
              type="image"
              title="Download image"
            />
          )}
          {translationEdge.node.fields.word && (
            <ExternalFile
              filename={translationEdge.node.fields.word}
              languageName={language.name}
              type="word"
              title="Download Word document"
            />
          )}
          {language.zip && (
            <>
              <ExternalFile
                filename={language.zip}
                type="font"
                title="Download font"
              />
              <Link to="/installing-fonts">How to install this font</Link>
            </>
          )}
        </div>
      ))}
    </React.Fragment>
  )

  const pageTitle = (
    <>
      {language.name}
      {" - "}
      <Phrase text={phrase.name} langName={language.name} />
    </>
  )

  return (
    <Layout>
      <HeadMeta pageTitle={pageTitle} />
      <PageHeader>{pageTitle}</PageHeader>
      <Breadcrumb
        route={[
          { name: "Multilingual library signage", href: "/" },
          { name: "Languages", href: "/language/" },
          { name: language.name, href: `/${language.slug}` },
          {
            name: <Phrase text={phrase.name} />,
            href: null,
          },
        ]}
      />

      {translationList}

      <p className={css.gutterLinks}>
        <Link className={css.gutterLinkButton} to={`/${phrase.slug}`}>
          See this phrase in other languages
        </Link>
        <Link className={css.gutterLinkButton} to={`/${language.slug}`}>
          Find other phrases in {language.name}
        </Link>
      </p>
      <h2>Disclaimer</h2>
      <p>
        The Multilingual Glossary of library terms has been professionally
        translated and designed to be used in a library environment. We strongly
        recommend you do not alter or combine glossary phrases.
      </p>
      <RequestAPhrase />
      <p>
        <Button
          onClick={e => {
            e.preventDefault()
            window.history.go(-1)
          }}
        >
          Return to the previous page
        </Button>
      </p>
    </Layout>
  )
}

export const query = graphql`
  query($languageID: Int, $phraseID: Int) {
    languagesJson(fields: { id: { eq: $languageID } }) {
      fields {
        name
        nameTranslated
        isoCode
        encoding
        direction
        font
        zip
        slug
      }
    }

    phrasesJson(fields: { id: { eq: $phraseID } }) {
      fields {
        name
        slug
      }
    }

    allTranslationsJson(
      filter: {
        fields: { enabled: { eq: true }, phraseID: { eq: $phraseID }, languageID: { eq: $languageID } }
      }
    ) {
      edges {
        node {
          fields {
            graphic
            id
            text
            word
          }
        }
      }
    }
  }
`
