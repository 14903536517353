// extracted by mini-css-extract-plugin
export var baseline = "8px";
export var borderRadius = "2px";
export var borderRadiusLg = "4px";
export var borderRadiusMd = "2px";
export var button = "languagePhrase-module--button--kW6Gd";
export var buttonUnstyled = "languagePhrase-module--buttonUnstyled--IoQXk";
export var colourBlack = "#111";
export var colourGrey800 = "#333";
export var colourGrey900 = "#222";
export var colourHighlight = "#ed0180";
export var colourPrimary = "#f68b1f";
export var colourSecondary = "#64c3af";
export var colourWhite = "#fff";
export var dark = "languagePhrase-module--dark--KBUpi";
export var fontFamilyPrimary = "\"Gotham A\",\"Gotham B\",Montserrat,Helvetica,Arial,sans-serif";
export var fontFamilySecondary = "\"Chronicle Display A\",\"Chronicle Display B\",\"Crimson Text\",\"Times New Roman\",Times,serif";
export var fontScale = "1.3";
export var formRow = "languagePhrase-module--formRow--ikmh8";
export var grey = "languagePhrase-module--grey--pnQn5";
export var gutterLinkButton = "languagePhrase-module--gutterLinkButton--9fs0w";
export var gutterLinks = "languagePhrase-module--gutterLinks--HPXBM";
export var lg = "languagePhrase-module--lg--nY2vH";
export var listUnstyled = "languagePhrase-module--listUnstyled--XIDp3";
export var mediaLg = "60rem";
export var mediaMd = "40rem";
export var mediaSm = "33.5rem";
export var mediaXlg = "80rem";
export var mediaXs = "20rem";
export var mediaXxlg = "100rem";
export var screenreaderOnly = "languagePhrase-module--screenreaderOnly--3koKb";
export var shadow = "languagePhrase-module--shadow--wMUp8";
export var sm = "languagePhrase-module--sm--n140D";
export var submitButton = "languagePhrase-module--submitButton--NhFb1";
export var textFieldTitle = "languagePhrase-module--textFieldTitle--Lx0f3";
export var textSmoothing = "languagePhrase-module--textSmoothing--MgHVt";
export var textUppercase = "languagePhrase-module--textUppercase--FwfC4";
export var translationError = "languagePhrase-module--translationError--0bC8L";