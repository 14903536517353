import React from "react"
import { Link } from "gatsby"

export default () => {
  return (
    <>
      <h2>Request a phrase for translation</h2>
      <p>
        If you cannot find the phrase you require, you can{" "}
        <Link to="/request/">request a phrase</Link> for inclusion.
      </p>
    </>
  )
}
